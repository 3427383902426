<template>
    <div>

    </div>
</template>

<script>
export default {
  name: 'MashPushPreview',

  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>
