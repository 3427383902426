<template>
    <Modal class="comp-set game-modal" footer-hide width="400" v-model="isDisplay" >
        <div class="title">
            设置直播间
        </div>
        <div class="content">
            直播间设置
        </div>
    </Modal>
</template>

<script>
export default {
  name: 'MashPushSet',
  data() {
    return {
      isDisplay: false,
    };
  },
  mounted() {
  },
  methods: {
    display() {
      this.isDisplay = !this.isDisplay;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
