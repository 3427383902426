<template>
    <div class="game-comp">
        <div class="game-list">
                <div class="game-item" @click="open('pack')" >
                  <icon-svg icon-class="icon-hongbao"></icon-svg>
                  <font class="name">发红包</font>
                </div>
                <div class="game-item"  @click="open('notice')" >
                  <icon-svg icon-class="icon-tonggao"></icon-svg>
                  <font class="name">发公告</font>
                </div>
                <!-- <div class="game-item"  @click="open('bag')" >
                  <icon-svg icon-class="icon-lipin"></icon-svg>
                  <font class="name">发福袋</font>
                </div> -->
                <div class="game-item"  @click="open('order')" >
                  <icon-svg icon-class="icon-liaotianshenqing"></icon-svg>
                  <font class="name">虚拟订单</font>
                </div>
                <div class="game-item"  @click="open('hot')" >
                  <icon-svg icon-class="icon-remai"></icon-svg>
                  <font class="name">虚拟人气</font>
                </div>
                <div class="game-item"  @click="open('ticket')" >
                  <icon-svg icon-class="icon-baoxiao"></icon-svg>
                  <font class="name">门票付费</font>
                </div>
                <div class="game-item"  @click="open('pwd')" >
                  <icon-svg icon-class="icon-mima"></icon-svg>
                  <font class="name">观看密码</font>
                </div>
                <!-- <div class="game-item"  @click="open('bind')" >
                  <icon-svg icon-class="icon-saoma"></icon-svg>
                  <font class="name">邀请码</font>
                </div> -->
                <!-- <div class="game-item"  @click="open('preview')" >
                  <icon-svg icon-class="icon-zhibo"></icon-svg>
                  <font class="name">预览直播</font>
                </div> -->
                <!-- <div class="game-item"  @click="open('set')" >
                  <icon-svg icon-class="icon-shezhi"></icon-svg>
                  <font class="name">直播设置</font>
                </div> -->
            </div>

           <div class="game-modal-fun">
             <!-- 福袋 -->
            <bag ref="bag"></bag>

            <!-- 邀请码 -->
            <bind ref="bind"></bind>

            <!-- 人气设置 -->
            <hot ref="hot"></hot>

            <!-- 公告设置 -->
            <notice ref="notice"></notice>

            <!-- 虚拟订单 -->
            <order ref="order"></order>

            <!-- 红包 -->
            <pack ref="pack"></pack>

            <!-- 预览 -->
            <preview ref="preview"></preview>

            <!-- 密码观看 -->
            <pwd ref="pwd"></pwd>

            <!-- 直播间设置 -->
            <set ref="set"></set>

            <!-- 门票设置 -->
            <ticket ref="ticket"></ticket>
           </div>

    </div>
</template>

<script>
import bag from './fun/bag/bag';
import bind from './fun/bind/bind';
import hot from './fun/hot/hot';
import notice from './fun/notice/notice';
import order from './fun/order/order';
import pack from './fun/pack/pack';
import preview from './fun/preview/preview';
import pwd from './fun/pwd/pwd';
import set from './fun/set/set';
import ticket from './fun/ticket/ticket';

export default {
  name: 'game',
  components: {
    bag,
    bind,
    hot,
    notice,
    order,
    pack,
    preview,
    pwd,
    set,
    ticket,
  },
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {
    open(name) {
      if (name == 'preview') {
        this.$parent.$refs.preview.display();
      } else {
        this.$refs[name].display();
      }
    },
  },
};
</script>

<style lang="scss" >
    .game-list {
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .game-list .game-item {
        width: 60px;
        min-width: 60px;
        height: 60px;
        padding: 10px;
        margin: 3px;
        margin-bottom: 10px;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        border-radius: 6px;
        transition: 0.3s;
    }
    .game-list .game-item:hover {
        background-color: #313341;
        transition: 0.3s;
    }
    .game-list .game-item .icon {
        font-size: 26px;
        margin-top: 5px;
        margin-bottom: 5px;
    }


</style>
