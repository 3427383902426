<template>
  <div class="page-push">
     <div class="header drag">
        <div class="logo " >
            <img class="img" src="https://media.oz4.cn/assets/img/logo-dark.png"></img>
        </div>
        <div class="room-info no-drag">
          <div class="room-logo">
              <img class="img" :src="room_info.cover_img"></img>
          </div>
          <div class="room-name">
            {{room_info.name}}
          </div>
          <!-- <div class="preview" @click="preview()">
              预览
          </div> -->
        </div>
        <div class="nav no-drag">
          <div @click="open('panel')" class="item">
            <icon-svg icon-class="icon-all"></icon-svg>
            <font class="name">管理后台</font>
          </div>
          <div @click="open('play')" class="item">
            <icon-svg icon-class="icon-pcm"></icon-svg>
            <font class="name">大屏直播</font>
          </div>
          <div @click="open('data')" class="item">
            <icon-svg icon-class="icon-training"></icon-svg>
            <font class="name">数据看板</font>
          </div>
          <div @click="open('chat-review')" class="item">
            <icon-svg icon-class="icon-training"></icon-svg>
            <font class="name">评论审核</font>
          </div>
        </div>

        <Poptip class="user-info no-drag" trigger="click" content="提示内容" placement="bottom-end">
            <div class="user no-drag" >
                <img class="img" src="https://panel.oz4.cn//upload/common/images/20220610/20220610101119165487027950712.png" ></img>
                <font class="name">{{room_info.anchor_name}}</font>
                <Icon type="ios-arrow-down" />
            </div>
            <div class="user-con" slot="content">
                <div class="item" @click="switchRoom()">
                    切换直播间
                </div>
                <div class="item" @click="userOut()">
                    退出
                </div>
            </div>
          </Poptip>
        <window-setting></window-setting>
      </div>
    <div id="content" class="content">
      <div class="video">
        <rtc_push :room_info="this.room_info"></rtc_push>
      </div>
      <div class="operate">
      
        <div class="tab">
            <div class="item item-user" :class="tab_active == 'user' ? 'active':''" @click="switchTab('user')">
                <icon-svg icon-class="icon-canyuhuati"></icon-svg>
                <font class="name">观众</font>
            </div>
            <div class="item item-goods" :class="tab_active == 'goods' ? 'active':''" @click="switchTab('goods')">
                <icon-svg icon-class="icon-gouwuche"></icon-svg>
                <font class="name">商品</font>
            </div>
            <div class="item item-game" :class="tab_active == 'game' ? 'active':''" @click="switchTab('game')">
                <icon-svg icon-class="icon-youxi"></icon-svg>
                <font class="name">功能</font>
            </div>
            <div class="item item-set" :class="tab_active == 'set' ? 'active':''" @click="switchTab('set')">
                <icon-svg icon-class="icon-shezhi1"></icon-svg>
                <font class="name">设备</font>
            </div>
        </div>
        <div class="tab-con tab-con-user" v-show="tab_active == 'user'">
          <div class="close" @click="closeOperate">
          <icon-svg icon-class="icon-guanbi"></icon-svg>
          <font>关闭</font>
        </div>
          <div class="user-list">
            <div class="title">在线观众</div>
            <!-- <comp-participants></comp-participants> -->
          </div>
          <div class="msg-list">
            <div class="title">消息互动</div>
            <!-- <comp-chat></comp-chat> -->
          </div>
        </div>

        <div class="tab-con tab-con-goods" v-show="tab_active == 'goods'">
          <div class="close" @click="closeOperate">
          <icon-svg icon-class="icon-guanbi"></icon-svg>
          <font>关闭</font>
        </div>
          <div class="title">直播商品</div>
          <goods-list :room_info="room_info">
          </goods-list>
        </div>

        <div class="tab-con tab-con-game" v-show="tab_active == 'game'">
          <div class="close" @click="closeOperate">
          <icon-svg icon-class="icon-guanbi"></icon-svg>
          <font>关闭</font>
        </div>
            <div class="title">互动功能</div>
            <game>
            </game>
        </div>

        <div class="tab-con tab-con-set" v-show="tab_active == 'set'">
          <div class="close" @click="closeOperate">
          <icon-svg icon-class="icon-guanbi"></icon-svg>
          <font>关闭</font>
        </div>
            <live-setting></live-setting>
        </div>

      </div>
    </div>

    <!-- 预览直播间 -->
    <preview ref="preview"></preview>
    <!-- <room-beauty-dialog ref="roomBeautyDialog"></room-beauty-dialog> -->
  </div>
</template>

<script>

import liveSetting from '@/components/live-setting/index.vue';
import rtc_push from '@/components/push/push';

import goodsList from '@/components/mash-goods/goods-list/goods-list';
import preview from '@/components/mash-preview/mash-preview';

import game from '@/components/comp-game/index';
import windowSetting from '@/components/comp-window-setting/window-setting';


export default {
  name: 'push',
  data() {
    return {
      room_info: {
      },
      tab_active: 'user',
    };
  },
  components: {
    rtc_push,
    game,
    preview,
    windowSetting,
    goodsList,
    // roomBeautyDialog,
    liveSetting,
  },
  methods: {
    // 切换选项卡
    switchTab(name) {
      this.tab_active = name;
    },
    //关闭选项卡
    closeOperate(){
      this.tab_active = '';
    },
    // 预览直播间
    preview() {
      this.$refs.preview.display();
    },
    // 打开窗口
    open(type) {
      if (type == 'panel') {
        window.open('https://p.oz4.cn', '_blank', 'resizable,scrollbars,status');
      }
      if (type == 'play') {
        window.open(`https://pc.bx.oz4.cn/s${this.$store.state.manageState}`, '_blank', 'resizable,scrollbars,status');
      }
      if (type == 'data') {
        window.open(`https://pc.bx.oz4.cn/s${this.$store.state.manageState}/data`, '_blank', 'resizable,scrollbars,status');
      }
      if (type == 'chat-review') {
        this.$router.push({
          name: 'chat-review',
          query: {
            id: this.room_info.id,
          },
        });
      }
    },
    // 切换直播间
    switchRoom() {
      this.$Modal.confirm({
        title: '确认离开直播间吗？',
        content: '如果您正在开播中，离开直播间将中断当前直播！！',
        onOk: (res) => {
          console.log('ok');
          this.$router.push({
            name: 'index',
          });
        },
      });
    },
    // 用户退出
    userOut() {
      this.$Modal.confirm({
        title: '确认离开直播间吗？',
        content: '如果您正在开播中，离开直播间将中断当前直播！！',
        onOk: (res) => {
          this.$store.commit('logout');
          this.$router.push({
            name: 'signin',
          });
        },
      });
    },
    // 获取直播间信息
    async getRoomInfo() {
      await this.$api.getRoomInfo({
        room_id: this.$route.query.id,
      }).then((res) => {
        console.log(res);
        if (res.code >= 0) {
          this.room_info = res.data;

          // 如果直播间为竖屏 设置为竖屏流
          // if(res.data.scenes == 1){
          //   console.log('设置为竖屏流设置为竖屏流设置为竖屏流设置为竖屏流')
          //   this.$store.commit('UPDATE_VIDEO_PROFILE', {
          //       width: 720,
          //       height: 1280,
          //       frameRate: 30,
          //       bitrate: 2000,
          //   });

          // }
        }
      });
    },

    // 获取主播SIG
    // async genUserSig(){
    //   await this.$api.genUserSig({
    //     room_id: this.$route.query.id
    //   }).then((res) => {
    //     console.log(res);
    //     if (res.code >= 0) {
    //         this.userSig = res.data.userSig
    //         let name = res.data.user_id
    //         name = name.split('$$')
    //         console.log(name)
    //         this.anchorUserInfo.userId = res.data.user_id
    //     }
    //   });
    // },
    // 显示设置预设置弹窗
    // showDevicePresettingDialog() {
    //   this.$refs.roomDeviceDialog.handleShowDeviceDialog();
    // },
    // 显示美颜预设置弹窗
    // showBeautyPresettingDialog() {
    //   this.$refs.roomBeautyDialog.handleShowBeautyDialog();
    // },
    // 设置TUIPusher配置信息
    // async handlePusherInfo() {

    //   await this.getRoomInfo()
    //   await this.genUserSig()

    //   const userSig = this.userSig

    //   if (sdkAppId === '' || secretKey === '') {
    //     alert('basic.Please configure your SDKAPPID');
    //   }
    //   console.log( 'handlePusherInfo++++++++++++++++++++')
    //   console.log(userSig)
    //   console.log(this.roomInfo)
    //   console.log(this.anchorUserInfo)
    //   console.log()
    //   this.$store.commit('UPDATE_LIVE_STAGE', 'not_started');
    //   this.$store.commit('SET_APP_INFO', {
    //     sdkAppId,
    //     userSig,
    //   });
    //   this.$store.commit('SET_ROOM_ID', this.roomInfo.roomId);
    //   this.$store.commit('UPDATE_ROOM_NAME', this.roomInfo.roomName);
    //   this.$store.commit('UPDATE_USER_INFO', {
    //     userId: this.anchorUserInfo.userId,
    //     userName: this.anchorUserInfo.userName,
    //     userAvatar: this.anchorUserInfo.userAvatar,
    //   });
    // },
    // 退出直播间
    async handleExit() {
      console.log('重置直播间状态RESET_STATE!!!!!!!');
      await this.$store.commit('RESET_STATE');
      // 处理退出直播间
    },
    // 退出登录
    async handleLogout() {
      // 处理退出登录
    },
  },
  async created() {
    this.getRoomInfo();
    // console.log(this.$util)
    // await this.handlePusherInfo();
    // this.$eventBus.$on('exit', this.handleExit);
    // this.$eventBus.$on('logout', this.handleLogout);
  },
  mounted() {
    // this.showDevicePresettingDialog();
  },
  beforeDestroy() {
    // console.log('页面销毁!!!!!!!')
    // this.handleExit();
    // this.$eventBus.$off('exit', this.handleExit);
    // this.$eventBus.$off('logout', this.handleLogout);
  },
};
</script>

<style lang="less">
</style>
