import { render, staticRenderFns } from "./comp-end-btn.vue?vue&type=template&id=588b3dae&scoped=true&"
import script from "./comp-end-btn.vue?vue&type=script&lang=js&"
export * from "./comp-end-btn.vue?vue&type=script&lang=js&"
import style0 from "./comp-end-btn.vue?vue&type=style&index=0&id=588b3dae&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_ian4ko2mrzwjqfnil3wbeim5ne/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588b3dae",
  null
  
)

export default component.exports