<!--
 * @Description: 推流控制组件
 * @Date: 2021-11-01 14:44:42
 * @LastEditTime: 2021-11-09 15:45:11
-->
<template>
  <div class="stream-control-container">
    <div class="center-container">
      <div class="start-animation" v-if="countdown &gt; 0">
        <div class="number">{{countdown}}</div>
      </div>
    </div>
    <div class="footer-container">
      <div class="left-container">
        <comp-audio></comp-audio>
        <comp-video></comp-video>
      </div>
      <div class="right-container">
        <comp-network-quality></comp-network-quality>
        <comp-room-time></comp-room-time>
        <comp-end-btn  v-if="liveStage === LIVE_STAGE.ONGOING"></comp-end-btn>
        <Button v-if="liveStage === LIVE_STAGE.ENDED" type="success" size="large" @click="startLive">再次开播</Button>
        <Button v-if="liveStage === LIVE_STAGE.NOT_STARTED" type="success" size="large" @click="startLive">开始直播</Button>
        <Button v-if="liveStage === LIVE_STAGE.ONGOING" type="primary" size="large" @click="pauseLive">暂停直播</Button>
        <Button v-if="liveStage === LIVE_STAGE.PAUSED" type="primary" size="large" @click="goOnLive">继续直播</Button>
      </div>
    </div>
  </div>
</template>

<script>
import compEndBtn from './comp-end-btn.vue';
import compAudio from './comp-audio.vue';
import compVideo from './comp-video.vue';
import compNetworkQuality from './comp-network-quality.vue';
import compRoomTime from './comp-room-time.vue';
import { LIVE_STAGE } from 'constants/room';
import { mapState } from 'vuex';
export default {
  name: 'compStreamControl',
  data() {
    return {
      LIVE_STAGE,
      countdown: 0,
    };
  },
  components: {
    compEndBtn,
    compAudio,
    compVideo,
    compNetworkQuality,
    compRoomTime,
  },
  computed: {
    ...mapState({
      roomName: 'roomName',
      liveStage: 'liveStage',
    }),
  },
  methods: {
    // 播放开始直播动画 倒计时
    playStartAnimation() {
      this.countdown = 3;
      const interval = setInterval(() => {
        this.countdown = this.countdown - 1;
        if (this.countdown < 0) {
          clearInterval(interval);
        }
      }, 1000);
    },
    // 开始直播
    async startLive() {
      console.log('点击开始直播');
      if (this.isAudioMuted && this.isVideoMuted) {
        this.$message.warning('请选择麦克风，和视频设备.');
        return;
      }
      this.playStartAnimation();
      setTimeout(() => {
        this.$store.commit('UPDATE_LIVE_STAGE', LIVE_STAGE.ONGOING);
      }, 3000);
    },
    // 暂停直播
    pauseLive() {
      this.$store.commit('UPDATE_LIVE_STAGE', LIVE_STAGE.PAUSED);
    },
    // 继续直播
    goOnLive() {
      if (this.isAudioMuted && this.isVideoMuted) {
        this.$message.warning('Please turn your camera or mic on.');
        return;
      }
      this.$store.commit('UPDATE_LIVE_STAGE', LIVE_STAGE.ONGOING);
    },
  },
};
</script>

<style  scoped>
.stream-control-container{
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.stream-control-container .header-container{
  position: relative;
  width: 100%;
  height: 50px;
  background-color: $backgroundColor;
  padding: 0 4px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stream-control-container .header-container .left-container,
.stream-control-container .header-container .right-container{
  height: 100%;
  display: flex;
  align-items: center;
}
.stream-control-container .center-container{
  flex-grow: 1;
}
.stream-control-container .center-container .start-animation{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33,33,38,0.4);
  z-index: 99;
}
.stream-control-container .center-container .start-animation .number{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateY(-50%);
  font-size: 80px;
  color: #fff;
}
.stream-control-container .footer-container{
  position: relative;
  width: 100%;
  height: 60px;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.stream-control-container .footer-container .left-container{
  display: flex;
  align-items: center;
}
.stream-control-container .footer-container .right-container{
  display: flex;
  align-items: center;
}
.stream-control-container .footer-container .btn-container[data-v-50d85eba],
.stream-control-container .footer-container .ivu-btn{
  margin-left: 15px;
}

</style>
