<template>
    <Modal class="comp-hot  game-modal" footer-hide width="400" v-model="isDisplay" >
        <div class="title">
            发公告
        </div>
        <div class="content">
            <Form :model="form" :label-width="80">
                <FormItem label="内容">
                     <Input v-model="form.content" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="请输入公告内容..."></Input>
                </FormItem>
                <Button type="primary" @click="handleSubmit('formValidate')">
                    立即发送
                </Button>
            </Form>
        </div>
    </Modal>
</template>

<script>
export default {
  name: 'MashPushHot',
  data() {
    return {
      isDisplay: false,
      form: {
        content: '',
      },
    };
  },
  mounted() {
  },
  methods: {
    display() {
      this.isDisplay = !this.isDisplay;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
