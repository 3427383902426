<template>
    <Modal class="comp-preview" footer-hide width="400" v-model="isDisplay" >
        <div class="content">
            <QRcode width="160" height="160" :content="'https://pc.play.oz4.cn'" :load="false"></QRcode>
            <font class="info">手机扫一扫预览直播间</font>
            <copy content="https://play.oz4.cn/"></copy>
        </div>
    </Modal>
</template>

<script>
export default {
  name: 'MashPushMashPreview',
  data() {
    return {
      isDisplay: false,
    };
  },
  mounted() {

  },
  methods: {
    display() {
      this.isDisplay = !this.isDisplay;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
    padding:20px  0 ;
    text-align: center;
}
.info {
    display: inline-block;
    font-size: 14px;
    color: #999;
    padding-bottom: 15px;
}
</style>
