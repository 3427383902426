<template>
    <Modal class="comp-pwd game-modal" footer-hide width="400" v-model="isDisplay" >
        <div class="title">
            设置房间观看密码
        </div>
        <div class="content">
            <Form :model="form" :label-width="80">
                <FormItem label="打开密码">
                     <i-switch v-model="form.state" size="large">
                        <span slot="open">打开</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>

                <FormItem label="房间密码" v-show="form.state">
                    <Input v-model="form.pwd" placeholder="设置为空不需要密码"></Input>
                </FormItem>


                <Button type="primary" @click="setViewConditionSetting()">
                    保存
                </Button>
            </Form>
        </div>
    </Modal>
</template>

<script>
export default {
  name: 'MashPushPwd',
  data() {
    return {
      isDisplay: false,
      form: {
        pwd: '',
        state: false,
      },
      viewCondition: {

      },
    };
  },
  mounted() {
  },
  created() {
    this.getviewConditionSetting();
  },
  methods: {
    display() {
      this.isDisplay = !this.isDisplay;
    },
    // 获取观看条件设置
    getviewConditionSetting() {
      this.$api.getviewConditionSetting({
        room_id: this.$route.query.id,
      }).then((res) => {
        if (res.code >= 0) {
          this.viewCondition = res.data;
          if (res.data !== '') {
            this.form.pwd = res.data.view_condition_setting.password;
            this.form.state = true;
          }
        }
      });
    },
    // 设置密码
    setViewConditionSetting() {
      if (this.form.state == true && this.form.pwd == '') {
        this.$Message.warning('请输入密码！');
        console.log('设置密码');
        return;
      } if (this.form.state == false || this.form.pwd == '') {
        console.log('不设密码');
        this.form.pwd = '';
      }

      console.log(this.$route.query.id);
      this.$api.setViewConditionSetting({
        room_id: this.$route.query.id,
        type: this.viewCondition.view_condition,
        password: this.form.pwd,
      }).then((res) => {
        if (res.code >= 0) {
          this.display();
          this.$Message.success('设置成功');
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
