import { render, staticRenderFns } from "./comp-audio.vue?vue&type=template&id=4421d090&scoped=true&"
import script from "./comp-audio.vue?vue&type=script&lang=js&"
export * from "./comp-audio.vue?vue&type=script&lang=js&"
import style0 from "./comp-audio.vue?vue&type=style&index=0&id=4421d090&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_ian4ko2mrzwjqfnil3wbeim5ne/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4421d090",
  null
  
)

export default component.exports