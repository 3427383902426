<template>
    <Modal class="comp-order" footer-hide width="400" v-model="isDisplay" >
        <div class="title">
            虚拟订单
        </div>
        <div class="content">
            <div class="text">
                您可以在商品 -> 商品列表 ->设置虚拟订单 以烘托人气
            </div>
        </div>
    </Modal>
</template>

<script>
export default {
  name: 'MashPushOrder',
  data() {
    return {
      isDisplay: false,
    };
  },
  mounted() {
  },
  methods: {
    display() {
      this.isDisplay = !this.isDisplay;
    },
  },
};
</script>
<style lang="scss" scoped>
    .ivu-modal-body .title {
        font-size: 26px;
        font-weight: lighter;
    }
    .ivu-modal-body .content .ivu-form {
        margin-top: 20px;
        padding: 10px;
    }
    .ivu-modal-body .content .ivu-btn {
        float: right;
    }
    .ivu-modal-body .content .text {
        padding: 40rpx;
    }
</style>
