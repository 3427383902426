<template>
  <div class="setting-comp">
    <div class="title">音频设置</div>
    <div class="audio-setting setting-item">
      <div class="microphone-select select-item">
        <span class="set-title">麦克风</span>
        <Select class="select-style" v-model="choseMicrophoneId" placeholder="请选择一个麦克风">
          <Option v-for="item in microphoneList" :key="item.deviceId" :label="item.label" :value="item.deviceId"></Option>
        </Select>
      </div>
      <!-- <div class="speaker-select">
        <span class="set-title">扬声器</span>
        <Select class="select-style" v-model="choseSpeakerId" disabled="disabled" placeholder="请选择一个扬声器">
          <Option v-for="item in speakerList" :key="item.deviceId" :label="item.label" :value="item.deviceId"></Option>
        </Select>
      </div> -->
    </div>
    <div class="title">视频设置</div>
    <div class="setting-item">
      <div class="select-item"><span class="set-title">摄像头</span>
        <Select class="select-style" v-model="choseCameraId" placeholder="请选择摄像头">
          <Option v-for="item in cameraList" :key="item.deviceId" :label="item.label" :value="item.deviceId"></Option>
        </Select>
      </div>
    </div>
  </div>
</template>

<script>
import { AliRTSPusher } from 'aliyun-rts-pusher';
const pushClient = AliRTSPusher.createClient();

export default {
  name: 'liveSetting',
  data() {
    return {
      deviceManager: {},
      dialogVisible: false,
      activeTab: '',
      cameraList: [],
      choseCameraId: '',

      microphoneList: [],
      choseMicrophoneId: '',

      speakerList: [],
      choseSpeakerId: '',
      localStream: null,
      isOpenBeauty: true,
    };
  },
  components: {

  },
  computed: {
  },
  watch: {
    cameraList(val) {
      this.choseCameraId = val[0].deviceId;
    },
    microphoneList(val) {
      this.choseMicrophoneId = val[0].deviceId;
    },
    choseCameraId(val) {
      const choseCameraDevice = this.cameraList.find(item => item.deviceId === val);
      this.$store.commit('UPDATE_ACTIVE_CAMERA', choseCameraDevice);
    },
    choseMicrophoneId(val) {
      const choseMicrophoneDevice = this.microphoneList.find(item => item.deviceId === val);
      this.$store.commit('UPDATE_ACTIVE_MICROPHONE', choseMicrophoneDevice);
    },
  },
  methods: {
    // 获取设备
    async getDevice() {
      const deviceManager = await pushClient.getDeviceManager();
      this.deviceManager = JSON.parse(JSON.stringify(deviceManager));

      // 获取摄像头列表
      const cameraList = await deviceManager.getCameraList();
      this.cameraList = JSON.parse(JSON.stringify(cameraList));

      // 获取麦克风列表
      const micList = await deviceManager.getMicList();
      this.microphoneList = JSON.parse(JSON.stringify(micList));
    },
  },
  async created() {
    this.getDevice();
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.button-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.live-setting-dialog {
  text-align: left;
}

.audio-setting {
  padding:10px 25px;
  padding-top: 0;

}
.audio-setting .title {
    display:block;
    width:60px
  }
.audio-setting .select-style {
    width:240px;
}

.setting-item {
  padding:10px 25px;
  padding-top: 0;
}
.setting-item .select-item {
    margin-bottom:20px
}
.select-style {
    width:240px;
    flex-grow:1
}
.mirror-checkbox{
  margin-bottom:10px
}
</style>
