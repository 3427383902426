<template>
    <div class="goods">
                <font class="sub-title" v-if="explain">讲解中：</font>

        <div class="goods-explain" v-if="explain">
               <div class="goods-item">
                    <div class="goods-cover">
                        <img :src="$util.handleUrl(explain.goods_image)" class="image">
                    </div>
                    <div class="goods-info"><div class="goods-name">
                        <font class="name">{{explain.goods_name}}</font></div>
                        <div class="goods-bar"><div class="price"><font class="currency">￥</font>
                        <font class="shop-price">{{explain.price}}</font></div></div>
                        <div class="tools">
                             <ButtonGroup size="small" type="primary" shape="circle">
                                <Button @click="descendGoods(explain)" type="button" class="ivu-btn ivu-btn-default">  <span>下链接</span></Button>
                                <Button  @click="displayOrderModal(explain)">虚拟单</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
        </div>
        <!-- <font class="sub-title">商品列表：</font> -->

        <div class="goods-list">
            <div class="goods-item" v-for="(item,index) in goods_list.list" :key="index">
                <div class="goods-cover">
                    <img class="image" :src="$util.handleUrl(item.goods_image)"> </img>
                </div>
                <div class="goods-info">
                    <div class="goods-name">
                    <font class="index">{{index+1}}</font>
                        <font class="name">{{item.goods_name}}</font></div>
                    <div class="goods-bar">
                        <div class="price">
                            <font class="currency">￥</font>
                            <font class="shop-price">{{item.price}}</font>
                        </div>
                    </div>

                    <div class="tools">
                            <ButtonGroup size="small" type="primary" shape="circle">
                                <Button @click="putGoods(item)">上链接</Button>
                                <Button @click="displayOrderModal(item)">虚拟单</Button>
                                <Button @click="switchGoodsState(item,'0')" v-if="item.goods_state != 0">下架</Button>
                                <Button @click="switchGoodsState(item,'1')" v-if="item.goods_state == 0">上架</Button>
                            </ButtonGroup>
                    </div>
                </div>
            </div>

            <!-- <div class="add-goods">
                <icon-svg icon-class="icon-add"></icon-svg>
                添加商品
            </div> -->
        </div>
        <Modal v-model="order_modal"  @on-ok="virtualOrder" title="虚拟订单">
                <Form ref="order_modal" :model="form" >
                    <FormItem label="商品名称">
                        <Input v-model="form.goods_name" disabled></Input>
                    </FormItem>
                    <FormItem label="订单数量">
                        <Input v-model="form.order_num" ></Input>
                    </FormItem>
                </Form>
            </Modal>

    </div>
</template>
<script>
export default {
  name: 'MashPushGoodsList',
  data() {
    return {
      goods_list: [],
      explain: false,
      order_modal: false,
      form: {
        goods_name: '',
        order_num: 3,
        interval_time: 5,
      },
    };
  },
  props: {
    room_info: {
      type: Object,
      default: {},
    },
  },
  mounted() {

  },
  created() {
    this.getGoodsList();
  },
  methods: {
    // 获取商品列表
    getGoodsList() {
      console.log(this.$route.query.id);
      this.$api.getGoodsListByRoom({
        page: 1,
        page_size: 100,
        room_id: this.$route.query.id,
      }).then((res) => {
        if (res.code >= 0) {
          this.goods_list = res.data;
        }
      });
    },
    // 上链接
    putGoods(data) {
      console.log(this.$route.query.id);
      this.$api.putGoods({
        sku_id: data.sku_id,
        room_id: this.$route.query.id,
      }).then((res) => {
        if (res.code >= 0) {
          this.explain = data;
          this.$Message.success('上链接成功');
        } else {
          this.$Message.error(res.message);
        }
      });
    },

    // 链接
    descendGoods(data) {
      console.log(this.$route.query.id);
      this.$api.descendGoods({
        sku_id: data.sku_id,
        room_id: this.$route.query.id,
      }).then((res) => {
        if (res.code >= 0) {
          this.explain = '';
          this.$Message.success('下链接成功');
        } else {
          this.$Message.error(res.message);
        }
      });
    },
    // 商品上下架
    switchGoodsState(data, type) {
      console.log(this.$route.query.id);
      this.$api.switchGoodsState({
        goods_ids: data.goods_id,
        room_id: this.$route.query.id,
        goods_state: type, // 1上架 0下架
      }).then((res) => {
        if (res.code >= 0) {
          if (type == 1) {
            this.getGoodsList();
            this.$Message.success('上架成功');
          } else {
            this.getGoodsList();
            this.$Message.success('下架成功,下架之后用户将无法进行下单！！！');
          }
        }
      });
    },
    // 显示 虚拟订单弹框
    displayOrderModal(data) {
      this.form.goods_name = data.goods_name;
      this.order_modal = !this.order_modal;
    },
    // 虚拟订单
    virtualOrder() {
      if (!this.form.order_num) {
        this.$Message.error('请输入虚拟订单数量！');
        return;
      }
      this.$api.virtualOrder({
        goods_name: this.form.goods_name,
        order_num: this.form.order_num,
        interval_time: this.form.interval_time,
        room_id: this.$route.query.id,
      }).then((res) => {
        if (res.code >= 0) {
          this.$Message.success('虚拟订单发送成功！');
        } else {
          this.$Message.error(res.message);
        }
      });
    },

  },
};
</script>

<style lang="scss" scoped>

    .sub-title{
        padding: 0 15px ;
    }
    .goods-list {
        padding: 15px;
        padding-top: 0;
        height: 350px;
        overflow-y: scroll;
    }
    .goods-item {
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 10px;
        background-color: #20222b;
        display: flex;
        position: relative;
    }
    .goods-item .goods-cover {
        width: 60px;
        height:60px;
        margin-right: 10px;
    }
    .goods-item .index {
        display: inline-block;
        color: #fff;
        background-color:#5976F4;
        border-radius: 3px;
        font-size: 12px;
        width: 18px;
        height: 18px;
        text-align: center;
        line-height: 18px;
        margin-right: 5px;
    }
    .goods-item .goods-cover .image {
        width: 100%;
        height: 100%;
    }
    .goods-item .goods-info {
        width:180px;
    }
    .goods-item .goods-bar {
        height: 30px;
        width: 100px;
        margin-top: 2px;
    }
   .goods-item .price {
        color: #ed4014;
        font-weight: bold;
    }
   .goods-item .tools {
        position: absolute;
        right: 5px;
        bottom: 5px;
    }
      .goods-item .tools .ivu-btn-group {
        width: 100%;
    }

    .goods-explain {
        padding: 0 15px ;
    }

    .goods-explain .goods-item {
        background-color: #292a36;
    }
    // .goods-explain .goods-item .index {
    //     background-color: #292a36;
    // }
    // .goods-explain .goods-item .name {
    //     color: #fff;
    // }

    /* 按钮 */
    .ivu-btn {
        background-color: #292a36;
        border: none;
    }

    .ivu-btn:hover {
        background-color: rgba(255, 255, 255, 0.08);
        color: #5976F4;
    }
    .add-goods {
        position: absolute;
        right: 10px;
        bottom: 20px;
        padding: 5px 10px;
        border-radius: 20px;
        background-color: #5976F4;
        color:#fff;
        cursor: pointer;
        opacity: 0.8;
        transition: 0.6s;
    }
    .add-goods:hover {
        opacity: 1;
        transition: 0.6s;

    }
</style>
