<template>
    <Modal class="comp-pack game-modal" footer-hide width="400" v-model="isDisplay" >
        <div class="content">
            福袋
        </div>
    </Modal>
</template>

<script>
export default {
  name: 'MashPushPack',
  data() {
    return {
      isDisplay: false,
    };
  },
  mounted() {
  },
  methods: {
    display() {
      this.isDisplay = !this.isDisplay;
    },
  },
};
</script>
<style lang="scss" scoped>
    .ivu-modal-body .title {
        font-size: 26px;
        font-weight: lighter;
    }
    .ivu-modal-body .content .ivu-form {
        margin-top: 10px;
        overflow: hidden;
        padding: 10px;

    }
    .ivu-modal-body .content .ivu-btn {
        float: right;
    }
</style>
