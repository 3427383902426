<!--
 * @Description: 视频推流
-->

<template>
  <div class="pusher-rtc-container">

    <div id="container" class="container">
    </div>


    <stream-control class="stream-control"></stream-control>

    <!-- <div class="control-bar" style="bottom: 300px; z-index: 9999;">
      <div @click="startDevice()">
      startDevice
    </div> -->
    <!-- <div class="camera" >
                <span class="icon camera-on" v-if="!isVideoMuted">
                <svg-icon icon-name="camera"></svg-icon>
                </span>
                <span class="icon camera-off" >
                <svg-icon icon-name="video-muted"></svg-icon>
                </span>
            </div>

            <div class="getCamera btn" @click="getDevice()">
            获取设备
        </div>
        <div class="getCamera btn" @click="startDevice()">
            开启设备
        </div>

        deviceManager:
        <div class="deviceManager" v-for="(item,index)  in deviceManager">
            {{ item }}
        </div>

        cameraList：
        <div class="cameraList">
            <div class="item" :class="index == currentCamera ? 'active':''" v-for="(item,index) in cameraList" @click="switchCamera(index)">
                {{ item.label }}
            </div>
        </div>

        micList：
        <div class="micList">
        <div class="item" :class="index == currentMic ? 'active':''" v-for="(item,index)  in micList"  @click="switchMic(index)">
            {{ item.label }}
        </div>
        </div> -->

  </div>

  </div>
</template>

<script>
import { AliRTSPusher } from 'aliyun-rts-pusher';
import streamControl from '../stream-control';
const pushClient = AliRTSPusher.createClient();
const videoEffectManager = pushClient.getVideoEffectManager();


export default {
  name: 'rtc-push',
  props: {
    room_info: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      deviceManager: {

      },
      cameraList: [],
      currentCamera: 0,
      micList: {
      },
      currentMic: 0,
    };
  },
  components: {
    streamControl,
  },
  async created() {

  },
  computed: {
    isAudioMuted() {
      return this.$store.state.isAudioMuted;
    },
    isVideoMuted() {
      return this.$store.state.isVideoMuted;
    },
    activeCamera() {
      return this.$store.state.activeCamera;
    },
    activeMicrophone() {
      return this.$store.state.activeMicrophone;
    },
    liveStage() {
      return this.$store.state.liveStage;
    },
  },
  watch: {
    activeCamera(val) {
      this.startDevice();
    },
    activeMicrophone(val) {
      this.startDevice();
    },
    room_info(val) {
      this.startDevice();
    },
    liveStage(val, oldval) {
      console.log(val);
      console.log(oldval);
      if (val == 'ongoing' && oldval == 'not_started') {
        console.log('开始');
        this.doStartLive();
      }

      if (val == 'ongoing' && oldval == 'paused') {
        console.log('继续');
        this.doGoOnLive();
      }
      if (val == 'paused') {
        console.log('暂停');
        this.doPauseLive();
      }
      if (val == 'ended') {
        console.log('结束');
        this.doStopLive();
      }
    },
    isAudioMuted(val) {
      if (val) {
        this.muteAudio();
      } else {
        this.unmuteAudio();
      }
    },
    isVideoMuted(val) {
      if (val) {
        this.muteVideo();
      } else {
        this.unmuteVideo();
      }
    },
    room_info(val) {
      console.log(val);
      // 获取设备
      this.initDevice();
    },
  },
  methods: {

    isMobile() {
      let userAgentInfo = navigator.userAgent;
      let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      let getArr = Agents.filter(i => userAgentInfo.includes(i));
      return getArr.length ? true : false;
    },
    // 获取设备
    async initDevice() {
      const deviceManager = await pushClient.getDeviceManager();
      // 初始化预览窗口
      const videoEl = pushClient.setRenderView('container');
      // console.log(videoEl);

      videoEl.muted = true;
      videoEl.controls = false;
      videoEl.playsinline = true;
      videoEl['webkit-playsinline'] = true;
      // videoEl.setAttribute('playsinline', true);
      // videoEl.setAttribute('webkit-playsinline', true);
      // console.log(videoEl);
      // 获取元素
      let video = document.querySelector('video');
      video.setAttribute('webkit-playsinline', true);  // 针对Chrome
      video.setAttribute('playsinline', true);  // 针对Safari
      // ve[1].setAttribute('playsinline', true);
      // ve[1].setAttribute('webkit-playsinline', true);
      // console.log(ve);
      // let Layout =  await videoEffectManager.getLayout();
      // console.log(Layout);
      // videoEffectManager.enableMixing(true);
      console.log(this.room_info.scenes);



      // 注册一个自定义 竖屏vertical
      AliRTSPusher.VideoProfileMap.set('vertical', { width: 720, height: 1280, frameRate: 25, maxBitrate: 3000 });
      // 注册一个自定义 横屏profile
      AliRTSPusher.VideoProfileMap.set('horizontal', { width: 1280, height: 720, frameRate: 25, maxBitrate: 3000 });

      // 使用这个 profile
      if (isMobile) { //如果是移动端 因为手机摄像机机制 需要反过来设置
        if (this.room_info.scenes == 0) {
          // 直播间设置为竖屏
          console.log('竖屏');
          await pushClient.setVideoQuality('horizontal');

        } else {
          // 直播间设置为横屏
          console.log('横屏');
          await pushClient.setVideoQuality('vertical');

        }
      } else {
        if (this.room_info.scenes == 0) {
          // 直播间设置为竖屏
          console.log('竖屏');
          await pushClient.setVideoQuality('vertical');

        } else {
          // 直播间设置为横屏
          console.log('横屏');
          await pushClient.setVideoQuality('horizontal');

        }
      }

      // pushClient.setVideoQuality('720p_3');
      pushClient.on('error', (err) => {
        console.log(err.errorCode);
      });

      pushClient.on('connectStatusChange', (event) => {
        console.log(event);
        switch (event.status) {
          case 1:
            // 连接中
            console.log('连接中');
            break;
          case 2:
            // 已连接
            console.log('已连接');

            break;
          case 3:
            // 自动重连中，注意此时推流已暂时中断，应及时提示用户
            console.log('自动重连中');

            break;
          case 0:
            // 连接中断，推流结束，应提示用户，必要时重新调用 startPush 进行推流
            console.log('连接中断，推流结束');
            if (this.liveStage == 'ongoing') {
              this.doStartLive();
            }
            break;
        }
      });
      // 打开设备
      setTimeout(() => {
        this.startDevice();
      }, 1000);
    },

    // 打开设备
    async startDevice() {
      // if(!this.deviceVideoId){
      // 打开摄像头
      console.log('打开摄像头');
      const cameraDevice = this.activeCamera.deviceId;
      // console.log(cameraDevice);
      const cv = await pushClient.startCamera(cameraDevice);
      // this.deviceVideoId  = cv;
      // console.log(cv);
      // const video = document.getElementById(cv);
      // console.log(video);
      // video.playsinline = true;
      // video['webkit-playsinline'] = true;
      // video.setAttribute('webkit-playsinline', true);  // 针对Chrome
      // video.setAttribute('playsinline', true);  // 针对Safari
      // console.log(cv);
      // 打开麦克风
      // console.log('打开麦克风');
      const micDevice = this.activeMicrophone.deviceId;
      await pushClient.startMicrophone(micDevice);
      // }else {
      //   const video = document.getElementById(this.deviceVideoId);
      //   console.log(video);
      //   video.play();
      // }
    },

    // 开始直播
    async doStartLive() {
      console.log('倒计时结束后开始推流');
      // 倒计时结束后开始推流
      //   await this.handlePublish();
      pushClient.startPush(this.room_info.push_url.artc_url);
    },
    // 暂停直播
    async doPauseLive() {
      this.muteAudio();
      this.muteVideo();
      //   await this.handleUnPublish();
    },
    // 继续直播
    async doGoOnLive() {
      this.unmuteAudio();
      this.unmuteVideo();
    },
    // 结束直播
    async doStopLive() {
      pushClient.stopPush();
      //   await this.handleUnPublish();
      //   await this.handleLeave();
    },

    // 关闭视频
    muteVideo() {
      pushClient.disableVideo();
    },

    // 关闭音频
    muteAudio() {
      pushClient.disableAudio();
    },

    // 打开视频
    unmuteVideo() {
      pushClient.enableVideo();
    },

    // 打开音频
    unmuteAudio() {
      pushClient.enableAudio();
    },

  },
  mounted() {


  },
};
</script>

<style>
.active {
  background-color: #3860f4;
  color: #fff;
}

.pusher-rtc-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}


.container video {
  width: 100%;
  height: 100%;
}

.control-bar {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

}

.btn {
  right: 10px;
  bottom: 20px;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: #33b50b;
  color: #fff;
  cursor: pointer;
  transition: 0.6s;
}

.control-bar .start {}

/* 隐藏video 全屏按钮 */
</style>
